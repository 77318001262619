export const ASC = 1;
export const DESC = -1;
export const NONE = 0;

export const sort = (data, sortField, lookups) => {
  return [ ...data ].sort(comparator(sortField, lookups));
};

const comparator = (field, lookups) => {
  return (o1, o2) => {
    const a = deriveComparable(o1, field, lookups);
    const b = deriveComparable(o2, field, lookups);
    const fieldName = field.alias || field.name;
    let result = sortableNumber(a[fieldName]) || sortableNumber(b[fieldName])
      ? sortAsNumbers(a[fieldName], b[fieldName])
      : sortAsNonNumbers(a[fieldName], b[fieldName]);
    return order(result, determineOrder(field));
  };
};

const deriveComparable = (o, field, lookups) => {
  const fieldName = field.alias || field.name;
  if (Object.keys(o).includes(fieldName)) {
    return o;
  } else if (lookups) {
    const lookup = lookups.find(l => l.fields.includes(fieldName));
    if (lookup) {
      const comparable = lookup.data[lookup.key ? o[lookup.key] : o] || {};
      if (!Object.keys(comparable).includes(fieldName)) {
        comparable[fieldName] = lookup.orElse;
      }
      return comparable;
    }
  } else {
    return {
      [fieldName]: 0
    };
  }
};

const sortableNumber = x => {
  return x !== '' && !isNaN(x);
};

const sortAsNumbers = (a, b) => {
  if (sortableNumber(a) && sortableNumber(b)) {
    return a - b;
  } else if (sortableNumber(a)) {
    return -1;
  } else if (sortableNumber(b)) {
    return 1;
  } else {
    return 0;
  }
};

const sortAsNonNumbers = (a, b) => {
  if (a && b) {
    return (a > b) - (a < b);
  } else if (a) {
    return -1;
  } else {
    return 1;
  }
};

const order = (result, order) => {
  return result * order;
};

export const sortedFields = (fields, sortField) => {
  return fields.map(field => newField(field, sortField));
};

const newField = (field, sortField = {}) => {
  let orderedBy = field.name === sortField.name ? determineOrder(sortField) : NONE;
  return { ...field, orderedBy };
};

const determineOrder = field => {
  return field.orderedBy ? reverse(field.orderedBy) : field.order;
};

const reverse = order => {
  return order * -1;
};
