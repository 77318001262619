import { useForecast } from './redux';
import { LoadingGif } from 'app/components';
import HourlyForecast from './HourlyForecast';
import DomeIcon from 'app/svg/DomeIcon';
import StadiumIcon from 'app/svg/StadiumIcon';

const domeDescription = forecast => `Dome w/ ${forecast.hasRetractable ? 'retractable roof' : 'fixed roof'}`;

const ForecastData = ({ forecast }) => {
  return (
    <div className="my-4 ms-3">
      <div className="mb-5">
        <div className="d-flex align-items-start">
          <div className="me-5">
            <div className="fs-5">{forecast.stadium}</div>
            <div><small>{forecast.isDome ? domeDescription(forecast) : 'Open Air Stadium'}</small></div>
            <div><small>{forecast.cityAndState}</small></div>
          </div>
          <div className="d-inline-block">
            {forecast.isDome ? <DomeIcon tooltip={domeDescription(forecast)} /> : <StadiumIcon /> }
          </div>
        </div>
      </div>
      <div className="mb-5">
        <h5>{forecast.brief}</h5>
        <p>{forecast.summary}</p>
      </div>
      <div className="row">
        <h5>Hourly Forecast</h5>
        { forecast.hourlyDetails.map(hourly => <HourlyForecast key={hourly.startTime} hourlyDetail={hourly} />) }
      </div>
    </div>
  );
};

const NoForecastData = () => {
  return (
    <div className="my-4 ms-3">
      <h5>No Weather Data Available</h5>
    </div>
  );
};

const WeatherForecast = ({ forecast }) => {
  return forecast.updateTime ? <ForecastData forecast={forecast} /> : <NoForecastData />;
};

const Loading = () => {
  return (
    <div className="my-5">
      <LoadingGif />
    </div>
  );
};

const WeatherForecastWrapper = ({ week, game }) => {
  const forecast = useForecast(week, game);
  return forecast ? <WeatherForecast forecast={forecast} /> : <Loading />;
};

export default WeatherForecastWrapper;
