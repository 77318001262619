import { useSelector } from 'react-redux';
import { FETCH_FAILED } from 'app/api';
import { post } from 'app/util/rest-client';

/*
 * actions
 */
const REQUESTING_LOGIN = 'REQUESTING_LOGIN';
const requestingLogIn = () => ({
  type: REQUESTING_LOGIN
});

const REQUESTING_LOGOUT = 'REQUESTING_LOGOUT';
const requestingLogOut = () => ({
  type: REQUESTING_LOGOUT
});

const AUTHENTICATED = 'AUTHENTICATED';
const authenticated = member => ({
  type: AUTHENTICATED,
  member
});

const LOGIN_FAILED = 'LOGIN_FAILED';
const logInFailed = () => ({
  type: LOGIN_FAILED
});

const CLEAR_FAILED_LOGIN = 'CLEAR_FAILED_LOGIN';
export const clearFailedLogIn = () => ({
  type: CLEAR_FAILED_LOGIN
});

const LOGGED_OUT = 'LOGGED_OUT';
const loggedOut = () => ({
  type: LOGGED_OUT
});


/*
 * reducer
 */
const auth = (state = {}, action) => {
  if ([ REQUESTING_LOGIN, REQUESTING_LOGOUT ].includes(action.type)) {
    return { requesting: true };
  } else if (action.type === AUTHENTICATED) {
    return { ...action.member };
  } else if (action.type === LOGIN_FAILED) {
    return { logInFailed: true };
  } else if (isClearAuth(action) || isUnauthorizedFetch(action)) {
    return {};
  } else {
    return state;
  }
};

const isClearAuth = action => [ CLEAR_FAILED_LOGIN, LOGGED_OUT ].includes(action.type);

const isUnauthorizedFetch = action => action.type === FETCH_FAILED && action.status === 401;

export default auth;

/*
 * hooks
 */
export const useMember = () => {
  return useSelector(({ auth }) => auth);
};

/*
 * thunks
 */
export const requestLogIn = (email, password) => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    if (auth.requesting) {
      return;
    }
    dispatch(requestingLogIn());
    post('api/login', { email, password })
      .then(response => response.json())
      .then(member => dispatch(authenticated(member)))
      .catch(() => dispatch(logInFailed()));
  };
};

export const requestLogOut = () => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    if (auth.requesting) {
      return;
    }
    dispatch(requestingLogOut());
    post('api/logout')
      .then(() => dispatch(loggedOut()))
      .catch(e => {
        console.error(e);
        dispatch(loggedOut());
      });
  };
};
