import authText from './text.json';
import { useCallback } from 'react';

const EmailInput = ({ email, setEmail }) => {
  const onChange = useCallback(e => setEmail(e.target.value), [ setEmail ]);
  return (
    <div className="auth-input mx-auto mb-2">
      <input
        type="text"
        name="email"
        className="form-control"
        placeholder={authText.emailPrompt}
        value={email}
        aria-label={authText.emailPrompt}
        aria-required="true"
        autoCapitalize="none"
        autoComplete="email"
        autoCorrect="off"
        onChange={onChange}
      />
    </div>
  );
};

export default EmailInput;
