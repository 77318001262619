import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearFailedLogIn, requestLogIn, useMember } from './redux';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';
import SubmitButton from './SubmitButton';
import authText from './text.json';
import './LogInForm.scss';

const LogInForm = () => {

  const member = useMember();
  const dispatch = useDispatch();
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ logInFailed, setLogInFailed ] = useState(false);

  useEffect(() => {
    if (member.logInFailed) {
      setLogInFailed(true);
      dispatch(clearFailedLogIn());
    }
  }, [ dispatch, member ]);

  const submit = useCallback(e => {
    e.preventDefault();
    dispatch(requestLogIn(email, password));
  }, [ dispatch, email, password ]);

  return (
    <form className="auth-form" onSubmit={submit}>
      <EmailInput email={email} setEmail={setEmail} />
      <PasswordInput password={password} setPassword={setPassword} />
      <SubmitButton purpose={authText.logIn} />
      <div className="login-failed mx-auto py-1 text-danger">
        { logInFailed ? authText.logInFailed : '' }
      </div>
    </form>
  );
};

export default LogInForm;
