import { useCallback } from 'react';
import { useSelectedPlayerIdDispatch, useShowPlayerModalDispatch } from './modal';
import { putCustom } from 'app/api';
import { indication } from 'app/injuries';
import { useSlate } from 'app/slates';
import { asUsDollars } from 'app/util';
import { fourTimesValue } from 'app/util/calculations';
import InterestIndicator from './InterestIndicator';

const useFieldDictionary = () => {
  const slate = useSlate();
  const select = useSelectedPlayerIdDispatch();
  const showModal = useShowPlayerModalDispatch();

  return {
    interest: {
      name: 'interest',
      type: 'component',
      header: '',
      noSorting: true,
      Component: ({ record }) => <InterestIndicator player={record} />
    },
    name: {
      name: 'name',
      type: 'component',
      header: 'name',
      cellClassname: 'text-start',
      order: 1,
      orderedBy: 0,
      Component: ({ record }) => {
        const statusIndication = indication(record.status);
        const onClick = useCallback(() => {
          select(record.id);
          showModal(true);
        }, [ record ]);
        return (
          <>
            <span className="link" onClick={onClick}>{record.name}</span>
            <span className="status-indication">{statusIndication}</span>
          </>
        );
      }
    },
    position: {
      name: 'position',
      header: 'pos',
      order: 1,
      orderedBy: 0
    },
    team: {
      name: 'team',
      header: 'tm',
      order: 1,
      orderedBy: 0
    },
    salary: {
      name: 'salary',
      type: 'custom',
      header: 'salary',
      order: -1,
      orderedBy: 0,
      render: player => asUsDollars(player.salary)
    },
    fourTimesValue: {
      name: 'fourTimesValue',
      type: 'custom',
      alias: 'salary',
      header: 'x4',
      order: -1,
      orderedBy: 0,
      render: player => fourTimesValue(player.salary)
    },
    maxFps: {
      name: 'maxFps',
      header: 'max pts',
      order: -1,
      orderedBy: 0
    },
    avgFps: {
      name: 'avgFps',
      header: 'avg pts',
      order: -1,
      orderedBy: 0
    },
    covFps: {
      name: 'covFps',
      header: 'cov pts',
      order: 1,
      orderedBy: 0
    },
    opponent: {
      name: 'opponent',
      header: 'opp',
      order: 1,
      orderedBy: 0
    },
    oppPtsAgst: {
      name: 'oppPtsAgst',
      header: 'opa',
      order: -1,
      orderedBy: 0
    },
    priorOppPtsAgst: {
      name: 'priorOppPtsAgst',
      header: 'popa',
      order: -1,
      orderedBy: 0
    },
    completions: {
      name: 'completions',
      header: 'cmp',
      order: -1,
      orderedBy: 0
    },
    inside20Completions: {
      name: 'inside20Completions',
      header: 'in20cmp',
      order: -1,
      orderedBy: 0
    },
    inside10Completions: {
      name: 'inside10Completions',
      header: 'in10cmp',
      order: -1,
      orderedBy: 0
    },
    passAttempts: {
      name: 'passAttempts',
      header: 'att',
      order: -1,
      orderedBy: 0
    },
    inside20PassAttempts: {
      name: 'inside20PassAttempts',
      header: 'in20att',
      order: -1,
      orderedBy: 0
    },
    inside10PassAttempts: {
      name: 'inside10PassAttempts',
      header: 'in10att',
      order: -1,
      orderedBy: 0
    },
    passYds: {
      name: 'passYds',
      header: 'yds',
      order: -1,
      orderedBy: 0
    },
    inside20PassYds: {
      name: 'inside20PassYds',
      header: 'in20Yds',
      order: -1,
      orderedBy: 0
    },
    inside10PassYds: {
      name: 'inside10PassYds',
      header: 'ins10Yds',
      order: -1,
      orderedBy: 0
    },
    ydsPerPass: {
      name: 'ydsPerPass',
      header: 'avg',
      order: -1,
      orderedBy: 0
    },
    passYdsPerGame: {
      name: 'passYdsPerGame',
      header: 'yds/gm',
      order: -1,
      orderedBy: 0
    },
    passTds: {
      name: 'passTds',
      header: 'tds',
      order: -1,
      orderedBy: 0
    },
    inside20PassTds: {
      name: 'inside20PassTds',
      header: 'in20Tds',
      order: -1,
      orderedBy: 0
    },
    inside10PassTds: {
      name: 'inside10PassTds',
      header: 'ins10Tds',
      order: -1,
      orderedBy: 0
    },
    interceptions: {
      name: 'interceptions',
      header: 'ints',
      order: -1,
      orderedBy: 0
    },
    inside20Interceptions: {
      name: 'inside20Interceptions',
      header: 'in20Ints',
      order: -1,
      orderedBy: 0
    },
    inside10Interceptions: {
      name: 'inside10Interceptions',
      header: 'in10Ints',
      order: -1,
      orderedBy: 0
    },
    rushAttempts: {
      name: 'rushAttempts',
      header: 'rsh',
      order: -1,
      orderedBy: 0
    },
    inside20RushAttempts: {
      name: 'inside20RushAttempts',
      header: 'in20Rsh',
      order: -1,
      orderedBy: 0
    },
    inside10RushAttempts: {
      name: 'inside10RushAttempts',
      header: 'in10Rsh',
      order: -1,
      orderedBy: 0
    },
    inside5RushAttempts: {
      name: 'inside5RushAttempts',
      header: 'in5Rsh',
      order: -1,
      orderedBy: 0
    },
    avgRushAttempts: {
      name: 'avgRushAttempts',
      header: 'rsh/gm',
      order: -1,
      orderedBy: 0
    },
    rushYds: {
      name: 'rushYds',
      header: 'yds',
      order: -1,
      orderedBy: 0
    },
    inside20RushYds: {
      name: 'inside20RushYds',
      header: 'in20Yds',
      order: -1,
      orderedBy: 0
    },
    inside10RushYds: {
      name: 'inside10RushYds',
      header: 'in10Yds',
      order: -1,
      orderedBy: 0
    },
    inside5RushYds: {
      name: 'inside5RushYds',
      header: 'in5Yds',
      order: -1,
      orderedBy: 0
    },
    ydsPerRush: {
      name: 'ydsPerRush',
      header: 'avg',
      order: -1,
      orderedBy: 0
    },
    rushYdsPerGame: {
      name: 'rushYdsPerGame',
      header: 'yds/gm',
      order: -1,
      orderedBy: 0
    },
    rushTds: {
      name: 'rushTds',
      header: 'rshTds',
      order: -1,
      orderedBy: 0
    },
    inside20RushTds: {
      name: 'inside20RushTds',
      header: 'in20Tds',
      order: -1,
      orderedBy: 0
    },
    inside10RushTds: {
      name: 'inside10RushTds',
      header: 'in10Tds',
      order: -1,
      orderedBy: 0
    },
    inside5RushTds: {
      name: 'inside5RushTds',
      header: 'in5Tds',
      order: -1,
      orderedBy: 0
    },
    inside20PercentOfTeamRushAttempts: {
      name: 'inside20PercentOfTeamRushAttempts',
      header: 'in20%Att',
      order: -1,
      orderedBy: 0
    },
    inside10PercentOfTeamRushAttempts: {
      name: 'inside10PercentOfTeamRushAttempts',
      header: 'in10%Att',
      order: -1,
      orderedBy: 0
    },
    inside5PercentOfTeamRushAttempts: {
      name: 'inside5PercentOfTeamRushAttempts',
      header: 'in5%Att',
      order: -1,
      orderedBy: 0
    },
    targets: {
      name: 'targets',
      header: 'tgts',
      order: -1,
      orderedBy: 0
    },
    inside20Targets: {
      name: 'inside20Targets',
      header: 'in20Tgts',
      order: -1,
      orderedBy: 0
    },
    inside10Targets: {
      name: 'inside10Targets',
      header: 'in10Tgts',
      order: -1,
      orderedBy: 0
    },
    avgTargets: {
      name: 'avgTargets',
      header: 'tgts/gm',
      order: -1,
      orderedBy: 0
    },
    receptions: {
      name: 'receptions',
      header: 'rec',
      order: -1,
      orderedBy: 0
    },
    inside20Receptions: {
      name: 'inside20Receptions',
      header: 'in20Rec',
      order: -1,
      orderedBy: 0
    },
    inside10Receptions: {
      name: 'inside10Receptions',
      header: 'in10Rec',
      order: -1,
      orderedBy: 0
    },
    receivingYds: {
      name: 'receivingYds',
      header: 'yds',
      order: -1,
      orderedBy: 0
    },
    inside20ReceivingYds: {
      name: 'inside20ReceivingYds',
      header: 'in20Yds',
      order: -1,
      orderedBy: 0
    },
    inside10ReceivingYds: {
      name: 'inside10ReceivingYds',
      header: 'in10Yds',
      order: -1,
      orderedBy: 0
    },
    ydsPerReception: {
      name: 'ydsPerReception',
      header: 'yds/rec',
      order: -1,
      orderedBy: 0
    },
    recYdsPerGame: {
      name: 'recYdsPerGame',
      header: 'recYds/gm',
      order: -1,
      orderedBy: 0
    },
    receivingTds: {
      name: 'receivingTds',
      header: 'recTds',
      order: -1,
      orderedBy: 0
    },
    inside20ReceivingTds: {
      name: 'inside20ReceivingTds',
      header: 'in20Tds',
      order: -1,
      orderedBy: 0
    },
    inside10ReceivingTds: {
      name: 'inside10ReceivingTds',
      header: 'in10Tds',
      order: -1,
      orderedBy: 0
    },
    inside20PercentOfTeamTargets: {
      name: 'inside20PercentOfTeamTargets',
      header: 'in20%Tgts',
      order: -1,
      orderedBy: 0
    },
    inside10PercentOfTeamTargets: {
      name: 'inside10PercentOfTeamTargets',
      header: 'in10%Tgts',
      order: -1,
      orderedBy: 0
    },
    offensiveTds: {
      name: 'offensiveTds',
      header: 'offTds',
      order: -1,
      orderedBy: 0
    },
    fumbles: {
      name: 'fumbles',
      header: 'fbls',
      order: -1,
      orderedBy: 0
    },
    avgPercentSnaps: {
      name: 'avgPercentSnaps',
      header: 'snap %',
      order: -1,
      orderedBy: 0
    },
    weightedOpportunities: {
      name: 'weightedOpportunities',
      header: 'wop',
      order: -1,
      orderedBy: 0
    },
    grade: {
      name: 'grade',
      header: 'grade',
      order: -1,
      orderedBy: 0
    },
    projection: {
      name: 'projection',
      header: 'prjctn',
      order: -1,
      orderedBy: 0
    },
    points: {
      name: 'points',
      type: 'input',
      header: 'points',
      order: -1,
      orderedBy: 0,
      blurAction: (player, value) => putCustom(slate, player, 'points', value)
    },
    target: {
      name: 'target',
      type: 'input',
      header: 'target',
      order: -1,
      orderedBy: 0,
      blurAction: (player, value) => putCustom(slate, player, 'target', value)
    }
  };
};

const fieldIdsFor = fieldGroupId => {
  return {
    passing: [
      'name',
      'position',
      'team',
      'completions',
      'passAttempts',
      'passYds',
      'ydsPerPass',
      'passTds',
      'interceptions',
      'fumbles',
      'passYdsPerGame'
    ],
    red_zone_passing: [
      'name',
      'position',
      'team',
      'inside20Completions',
      'inside20PassAttempts',
      'inside20PassYds',
      'inside20PassTds',
      'inside20Interceptions',
      'inside10Completions',
      'inside10PassAttempts',
      'inside10PassYds',
      'inside10PassTds',
      'inside10Interceptions'
    ],
    rushing: [
      'name',
      'position',
      'team',
      'rushAttempts',
      'rushYds',
      'ydsPerRush',
      'rushTds',
      'fumbles',
      'avgRushAttempts',
      'rushYdsPerGame'
    ],
    red_zone_rushing: [
      'name',
      'position',
      'team',
      'inside20RushAttempts',
      'inside20RushYds',
      'inside20RushTds',
      'inside20PercentOfTeamRushAttempts',
      'inside10RushAttempts',
      'inside10RushYds',
      'inside10RushTds',
      'inside10PercentOfTeamRushAttempts',
      'inside5RushAttempts',
      'inside5RushYds',
      'inside5RushTds',
      'inside5PercentOfTeamRushAttempts'
    ],
    receiving: [
      'name',
      'position',
      'team',
      'targets',
      'avgTargets',
      'receptions',
      'receivingYds',
      'ydsPerReception',
      'recYdsPerGame',
      'receivingTds',
      'fumbles'
    ],
    red_zone_receiving: [
      'name',
      'position',
      'team',
      'inside20Targets',
      'inside20Receptions',
      'inside20ReceivingYds',
      'inside20ReceivingTds',
      'inside20PercentOfTeamTargets',
      'inside10Targets',
      'inside10Receptions',
      'inside10ReceivingYds',
      'inside10ReceivingTds',
      'inside10PercentOfTeamTargets'
    ],
    scoring: [
      'name',
      'position',
      'team',
      'rushTds',
      'receivingTds',
      'offensiveTds'
    ],
    dfs: [
      'interest',
      'name',
      'position',
      'team',
      'salary',
      'fourTimesValue',
      'maxFps',
      'avgFps',
      'opponent',
      'oppPtsAgst',
      'priorOppPtsAgst',
      'weightedOpportunities',
      'avgPercentSnaps',
      'grade',
      'points',
      'target'
    ]
  }[fieldGroupId];
};

const useFieldsFor = fieldGroupId => useFields(fieldIdsFor(fieldGroupId));

export const useFields = fieldIds => {
  const dict = useFieldDictionary();
  return fieldIds.map(id => dict[id]);
};


export const defaultPlayerStatsFieldGroup = () => ({
  id: 'passing',
  text: 'Passing'
});

export const playerStatsFieldGroups = () => [
  defaultPlayerStatsFieldGroup(),
  {
    id: 'rushing',
    text: 'Rushing'
  },
  {
    id: 'receiving',
    text: 'Receiving'
  },
  {
    id: 'red_zone_passing',
    text: 'Red Zone Passing'
  },
  {
    id: 'red_zone_rushing',
    text: 'Red Zone Rushing'
  },
  {
    id: 'red_zone_receiving',
    text: 'Red Zone Receiving'
  },
  {
    id: 'scoring',
    text: 'Scoring'
  }
];

export const usePlayerStatsFields = useFieldsFor;


export const defaultSlatePlayersFieldGroup = () => ({
  id: 'dfs',
  text: 'DFS'
});

export const slatePlayersFieldGroups = () => [
  defaultSlatePlayersFieldGroup(),
  ...playerStatsFieldGroups()
];

export const useSlatePlayersFields = fieldGroup => useFieldsFor(fieldGroup.id);
