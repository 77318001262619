import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { api, games, injuries, matchups, players, pvt, slates, teams, weather, weeks } from 'app/api/redux';
import auth from 'app/auth/redux';
import filters from 'app/filters/redux';
import lineups from 'app/lineups/redux';

/*
 * store
 */
const reducer = { api, auth, filters, games, injuries, lineups, matchups, players, pvt, slates, teams, weather, weeks };

const middleware = [ thunk ];

const loadState = () => {
  const state = localStorage.getItem('state');
  return state !== null ? JSON.parse(state) : {};
};

const logState = state => {
  console.log(state);
};

const saveState = state => {
  let toSave = {
    auth: state.auth,
    filters: state.filters,
    lineups: state.lineups,
    players: { custom: state.players.custom, interests: state.players.interests }
  };
  localStorage.setItem('state', JSON.stringify(toSave));
};

const makeStore = (seedState, options = { doLogging: true }) => {
  const store = configureStore({
    reducer,
    middleware,
    preloadedState: seedState || loadState()
  });

  if (options.doLogging) {
    store.subscribe(() => logState(store.getState()));
  }
  store.subscribe(() => saveState(store.getState()));

  return store;
};

export default makeStore;
