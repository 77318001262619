import { useSelectedGame } from './GamesViewContext';
import { useSlate } from 'app/slates';
import { WeatherForecast } from 'app/weather';

const WeatherForecastTab = () => {

  const slate = useSlate();
  const game = useSelectedGame(slate);

  return <WeatherForecast week={slate.week} game={game} />;
};

export default WeatherForecastTab;
