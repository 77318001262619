const HourlyForecast = ({ hourlyDetail }) => {
  return (
    <div className="col-3">
      <h6>{hourlyDetail.startTime} ET</h6>
      <ul>
        <li>{hourlyDetail.temperature}&deg; F</li>
        <li>{hourlyDetail.probabilityOfPrecipitation}% precipitation</li>
        <li>{hourlyDetail.windSpeed} {hourlyDetail.windDirection}</li>
        <li>{hourlyDetail.brief}</li>
      </ul>
    </div>
  );
};

export default HourlyForecast;
