import { useInjuries } from 'app/injuries';
import { useTeam } from 'app/teams';
import { findByTeam } from 'app/util';

const InjuriesSummaryTab = ({ week, game }) => {

  const injuries = useInjuries(week);

  const away = makeSummary({ ...useTeam(game.away, week), injuries: findByTeam(injuries, game.away) });
  const home = makeSummary({ ...useTeam(game.home, week), injuries: findByTeam(injuries, game.home) });

  return (
    <div className="row my-4">
      <Summary team={away} />
      <Summary team={home} />
    </div>
  );
};

const Summary = ({ team }) => {
  return (
    <>
      <div className="col-1">&nbsp;</div>
      <div className="col-4">
        <table className="table table-sm centered-columns">
          <thead>
            <tr>
              <th colSpan={4}>{team.name}</th>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <th>players</th>
              <th>games</th>
              <th>fte</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(team.positions).map(p => {
                return (
                  <tr key={p}>
                    <th>{p}</th>
                    <td>{team.positions[p].players}</td>
                    <td>{team.positions[p].games}</td>
                    <td>{team.positions[p].fte}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

const makeSummary = team => {

  const GRP = { players: 0, games: 0, percentSnaps: 0 };
  const TEMPLATE = { QB: GRP, RB: GRP, WR: GRP, TE: GRP, OL: GRP, DL: GRP, LB: GRP, DB: GRP };

  const summary = { name: team.name, positions: JSON.parse(JSON.stringify(TEMPLATE)) };

  team.injuries
    .filter(injured => !!injured.status)
    .forEach(injured => {
      const group = injured.positionGroup;
      const gamesPlayed = injured.gamesPlayed || 0;
      if (summary.positions[group]) {
        summary.positions[group].players += 1;
        summary.positions[group].games += gamesPlayed;
        summary.positions[group].percentSnaps += gamesPlayed * (injured.avgPercentSnaps || 0);
      }
    });

  Object.keys(summary.positions).forEach(group => {
    summary.positions[group].fte = team.gamesPlayed
      ? Math.round(summary.positions[group].percentSnaps / team.gamesPlayed) / 100
      : 0;
  });

  return summary;
};

export default InjuriesSummaryTab;
