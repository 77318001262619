import { useCallback } from 'react';
import authText from './text.json';

const PasswordInput = ({ password, setPassword }) => {
  const onChange = useCallback(e => setPassword(e.target.value), [ setPassword ]);
  return (
    <div className="auth-input mx-auto mb-2">
      <input
        type="password"
        name="password"
        className="form-control"
        placeholder={authText.passwordPrompt}
        value={password}
        aria-label={authText.passwordPrompt}
        aria-required="true"
        autoCapitalize="none"
        autoComplete="new-password"
        autoCorrect="off"
        onChange={onChange}
      />
    </div>
  );
};

export default PasswordInput;
